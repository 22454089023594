import React, { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Client } from 'tmi.js';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { 
    Context as TBCContext,
    ChatInterface
} from 'twitch-badge-collector-cc';

interface ChatRoomFooterProps {
    clientRef: MutableRefObject<Client | null>;
    setChatList: Dispatch<SetStateAction<ChatInterface.MessageInterface[]>>;
}

function ChatRoomFooter(props: ChatRoomFooterProps) {
    const { channel } = TBCContext.useChannelInfoContext();
    const { addAlert } = TBCContext.useAlertContext();
    const [ isComposing, setIsComposing ] = useState<boolean>(false);
    const [ message, setMessage ] = useState('');
    const { t } = useTranslation();

    const sendMessage = () => {
        const client = props.clientRef.current;

        if(client === null) return;
        
        const setChatList = props.setChatList;

        if(message !== '' && channel){
            client.say(channel.value, message).then(() => {
                setMessage('');
            }).catch(reason => {
                setChatList(chatList => [...chatList, {
                    type: 'system', message: reason, time: Date.now()
                }])
            });
        }
    }
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    }
    const enterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.code !== 'Enter') return;
        if (isComposing) return;
        if(!channel || channel.value === '') {
            addAlert({
                message: t('alert.no_connect_channel'),
                serverity: 'warning'
            });
            return;
        }
        sendMessage();
    }

    return (
        <Stack 
            direction="row" 
            justifyContent='space-between' 
            spacing={1}
            sx={{padding: '16px 8px 0 8px'}}
        >
            <TextField 
                placeholder={t('common.send_message')}
                onCompositionStart={()=>setIsComposing(true)}
                onCompositionEnd={()=>setIsComposing(false)}
                sx={{width: '100%'}}
                hiddenLabel

                autoComplete='off'
                size="small"
                value={message}
                onChange={onInputChange}
                onKeyDown={enterKeyDown} />
            <Button className="btn" id="send-Button" onClick={sendMessage} variant='contained'>Chat</Button>
        </Stack>
    )
    
}

export default ChatRoomFooter;
