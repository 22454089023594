import { useState } from 'react';
import { SettingDialog } from '../setting/SettingDialog';
import ChannelDialog from '../channel/ChannelDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faGear } from "@fortawesome/free-solid-svg-icons";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

const HeaderComp = styled(Stack)(({theme}) => ({
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3.4rem',
    margin: '0 20px 0 20px',
    color: theme.palette.text.primary
}));

function ChatRoomHeader() {
    const [openChannelDialog, setOpenChannelDialog] = useState(false);
    const [openSettingDialog, setOpenSettingDialog] = useState(false);

    return (
        <>
            <HeaderComp
                direction='row'
            >
                <Stack onClick={() => setOpenChannelDialog(true)} sx={{ padding: '8px' }}>
                    <FontAwesomeIcon icon={faBars} />
                </Stack>
                <Stack onClick={() => setOpenSettingDialog(true)} sx={{ padding: '8px' }}>
                    <FontAwesomeIcon icon={faGear} />
                </Stack>

                <ChannelDialog open={openChannelDialog} setOpen={setOpenChannelDialog} />
                <SettingDialog open={openSettingDialog} setOpen={setOpenSettingDialog} expand={true} />
            </HeaderComp>
            <Divider sx={{marginBottom: '8px'}}/>
        </>
    )
}

export default ChatRoomHeader;
