import { useRef, useEffect, useState } from 'react';
import { Client, Options } from 'tmi.js';
import * as Sentry from "@sentry/browser";
import { BroadcastChannel } from 'broadcast-channel';
import { nanoid } from 'nanoid';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getRandomArbitrary } from '../utils/utils';
import Auth from '../interface/auth';
import { 
    Context as TBCContext,
    ChatInterface,
    ChatInfoObjectsInterface,
    BroadcastChannelInterface,
    FilterInterface,
    ClientInterface,
    Utils
} from 'twitch-badge-collector-cc';

export default function useClient(options: {auth?: Auth, remoteType?: BroadcastChannelInterface.chatListType}) {
    const { globalSetting, dispatchGlobalSetting } = TBCContext.useGlobalSettingContext(); 
    const { channelInfoObject, dispatchChannelInfo, channel, setChannel } = TBCContext.useChannelInfoContext();
    const channelInfoObjectRef = useRef<ChatInfoObjectsInterface.ChatInfoObjects>(channelInfoObject);
    const [chatList, setChatList] = useState<ChatInterface.MessageInterface[]>([]);
    const [cloneChatList, setCloneChatList] = useState<ChatInterface.MessageInterface[]>([]);
    const userColorMapRef = useRef<Map<string, string>>(new Map());
    const messageId = useRef('');
    const [emoteSetsParams, setEmoteSetsParams] = useState<string[]>([]);
    const [connStatus, setConnStatus] = useState<ClientInterface.ConnStatusType | undefined>();
    const [contentScriptReady, setContentScriptReady] = useState(false);

    const filterBroadcastChannel = useRef<BroadcastChannel<FilterInterface.ArrayFilterMessageInterface>>(new BroadcastChannel('ArrayFilter'));
    const randomNickRef = useRef(`justinfan${getRandomArbitrary(1000, 9999)}`);
    const clientOptionsRef = useRef<Options>({
        options: {
            clientId: 'qrh8nkx7bzpij23zdudqsh05wzi9k0',
            skipUpdatingEmotesets: true,
            skipMembership: true
        },
        connection: { 
            reconnect: true, 
            secure: true,
        },
        identity: {
            username: randomNickRef.current,
            password: (options.auth && options.auth.authInfo) ? `oauth:${options.auth.authInfo.accessToken}` : ''
        }
    });
    const channelRef = useRef(channel);
    const clientRef = useRef<Client | null>(null);
    const maxNumChats = globalSetting.maximumNumberChats || 100;

    const { setArrayFilter, checkFilter } = TBCContext.useArrayFilterContext();
    const { addAlert } = TBCContext.useAlertContext();
    const twitchAPI = TBCContext.useTwitchAPIContext();
    const { t } = useTranslation();

    const { data: EmoteSets } = useQuery(
        ['EmoteSets', emoteSetsParams],
        () => twitchAPI.fetchEmoteSets(emoteSetsParams),
        {
            enabled: emoteSetsParams.length > 0
        }
    )
    const addMessage = (msg: ChatInterface.MessageInterface) => {
        const isRemoteLive = options.remoteType === 'live';
        const isRemoteReplay = options.remoteType === 'replay';

        msg.id = nanoid();

        if (msg.type === 'system') {
            addCloneMessage(msg);
        }
        if (msg.userstate) {
            const filterRes = checkFilter({
                textContents: [msg.message],
                badges: (msg.userstate["badges-raw"] || '').split(','),
                loginName: msg.userstate.username || msg.userstate.login,
                nickName: msg.userstate["display-name"] || '',
            }, channelInfoObjectRef.current);

            if (filterRes) {
                addCloneMessage(msg);
            }
        }
        if (!isRemoteLive && !isRemoteReplay) {
            setChatList(chatList => [...chatList, msg]);
        }
    }
    const addCloneMessage = (msg: ChatInterface.MessageInterface) => {
        setCloneChatList(cloneList => {
            if (options.remoteType === 'replay') {
                return cloneList.some(cl => {
                    if (msg.userstate && cl.userstate) {
                        return msg.userstate.id === cl.userstate.id;
                    } else {
                        return false;
                    }
                }) ? cloneList : [...cloneList, msg];
            } else {
                return [...cloneList, msg];
            }
        });
    }

    const deleteOldMessage = (type: 'orig' | 'clone') => {
        const setter = type === 'orig' ? setChatList : setCloneChatList;
        
        setter(list => {
            return list.length > maxNumChats ? list.slice(-maxNumChats) : list;
        });
    }

    const clearChat = (type: 'orig' | 'clone', line: boolean) => {
        const setter = type === 'orig' ? setChatList : setCloneChatList;

        setter(chatList => {
            if (line) {
                return chatList.map(c => {
                    c.removed = true
                    return c;
                });
            } else {
                return [];
            }
        });
    }

    const joinChannel = (channel: string) => {
        if (!channel || channel === '') return;

        const client = clientRef.current;

        if (client === null) return;

        Sentry.addBreadcrumb({
            type: 'useClient:joinChannel:channel',
            message: `channel: ${channel}`
        });
        
        client.getChannels().forEach(c => {
            client.part(c);
        });

        client.join(channel).then(() => {
            addMessage({
                id: nanoid(), type: 'system', message: t('tmi_system.channel_joined', { channel })
            });
        }).catch(err => {
            console.log('TBCV2 - [web] useClient joinChannel err: ', err);
        });
    }

    const onMessage = (e: MessageEvent<any>) => {
        if(e.data.sender !== 'extension') return;

        const type = e.data.type;
        const value = e.data.value;

        if(type === 'CONTENT_SCRIPT_READY'){
            setContentScriptReady(true);
        }
    }

    useEffect(() => {
        channelInfoObjectRef.current = channelInfoObject;
    }, [channelInfoObject]);

    useEffect(() => {
        const client = clientRef.current;

        if (client === null) return;
        if (!channel) return;

        channelRef.current = channel;

        const channelExist = client.getChannels().includes(channel.value);

        if (
            client !== null && 
            !channelExist && 
            client.readyState() === 'OPEN' && 
            channel.type === 'login' &&
            connStatus === 'connected'
        ) {
            joinChannel(channel.value);

            window.parent.postMessage({
                sender: 'wtbc',
                type: 'REQUEST_TBC_CROWN',
                value: null
            }, '*');
        }
    }, [channel, connStatus]);

    useEffect(() => {
        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        }
    }, []);

    useEffect(() => {
        channelRef.current = channel;
    }, [channel])

    useEffect(() => {
        filterBroadcastChannel.current.onmessage = (msg) => {
            if (messageId.current === '') {
                messageId.current = msg.msgId;
            }

            setArrayFilter(Utils.getFilterByPlatform('twitch', msg.filter));

            addAlert({
                message: t('alert.filter_updated'),
                serverity: 'info'
            });
        }
    }, []);

    useEffect(() => {
        if (chatList.length > maxNumChats) {
            setChatList(chatList => {
                return chatList.slice(-maxNumChats);
            });
        }
        if (cloneChatList.length > maxNumChats) {
            setCloneChatList(cloneList => {
                return cloneList.slice(-maxNumChats);
            });
        }
    }, [chatList, cloneChatList]);

    useEffect(() => {
        if (!EmoteSets) return;

        dispatchChannelInfo({ type: 'emotesets', value: EmoteSets });
    }, [EmoteSets]);

    useEffect(() => {
        if (options.remoteType === 'replay') return;
        if (options.auth && options.auth.authInfo.status === null) return;

        const opt = clientOptionsRef.current;
        const auth = options.auth;
        opt.identity!.username = auth && auth.authInfo ? (auth.authInfo.loginName || randomNickRef.current) : randomNickRef.current;
        opt.identity!.password = auth && auth.authInfo ? `oauth:${auth.authInfo.accessToken}` : '';

        if (clientRef.current !== null) return;

        clientRef.current = new Client(opt);
        clientRef.current.connect();

        clientRef.current.on('message', (channel, userstate, message, self) => {
            addMessage({
                type: 'message', channel, userstate, message
            });
        });

        clientRef.current.on("subscription", (channel, username, method, message, userstate) => {
            addMessage({
                type: 'userNotice', channel, userstate, message
            });
        });

        clientRef.current.on("cheer", (channel, userstate, message) => {
            addMessage({
                type: 'message', channel, userstate, message
            });
        });

        clientRef.current.on("giftpaidupgrade", (channel, username, sender, userstate) => {
            addMessage({
                type: 'userNotice', channel, userstate, message: userstate.message || ''
            });
        });

        clientRef.current.on("anongiftpaidupgrade", (channel, username, userstate) => {
            addMessage({
                type: 'userNotice', channel, userstate, message: userstate.message || ''
            });
        });

        clientRef.current.on("submysterygift", (channel, username, numbOfSubs, methods, userstate) => {
            addMessage({
                type: 'userNotice', channel, userstate, message: userstate.message || ''
            });
        });

        clientRef.current.on("resub", (channel, username, months, message, userstate, methods) => {
            addMessage({
                type: 'userNotice', channel, userstate, message
            });
        });

        clientRef.current.on('primepaidupgrade', (channel, username, methods, userstate) => {
            addMessage({
                type: 'userNotice', channel, userstate, message: userstate.message || ''
            });
        });

        clientRef.current.on("subgift", (channel, username, streakMonths, recipient, methods, userstate) => {
            addMessage({
                type: 'userNotice', channel, userstate, message: userstate.message || ''
            });
        });

        clientRef.current.on("emotesets", (sets, obj) => {
            let sets_arr = sets.split(',');
            for (let i = 0; i < sets_arr.length; i = i + 25) {
                setEmoteSetsParams(sets_arr.splice(0, 25));
            }
        });

        clientRef.current.on("raw_message", (messageCloned, message) => {

            const channel = message.params[0];
            const textMessage = message.params[1];
            const userstate = message.tags;

            if (message.tags['msg-id'] === 'announcement') {
                addMessage({
                    type: 'announcement', channel, userstate, message: textMessage
                });
            }
        });

        // Connection
        clientRef.current.on("connecting", (address, port) => {
            setConnStatus('connecting');
        });

        clientRef.current.on('connected', (address: string, port: number) => {
            setConnStatus('connected');
        });

        clientRef.current.on('reconnect', () => {

        });

        clientRef.current.on("disconnected", async (reason) => {
            setConnStatus('disconnected');
        });

        clientRef.current.on('part', (channel, username, self) => {

        });

        clientRef.current.on("emoteonly", (channel, enabled) => {
            const message = enabled ? t('tmi_system.emote_enabled') : t('tmi_system.emote_disabled');
            addMessage({ type: 'system', message });
        });

        clientRef.current.on("followersonly", (channel, enabled, length) => {
            const message = enabled ? t('tmi_system.follower_only_enabled', { length }) : t('tmi_system.follower_only_disabled');
            addMessage({ type: 'system', message });
        });

        clientRef.current.on("slowmode", (channel, enabled, length) => {
            const message = enabled ? t('tmi_system.slow_mode_enabled', { length }) : t('tmi_system.slow_mode_disabled');
            addMessage({ type: 'system', message });
        });

        clientRef.current.on('clearchat', (channel) => {
            clearChat('orig', true);
            clearChat('clone', true);
            
            const message = t('tmi_system.all_chat_removed');
            addMessage({ type: 'system', message });
        });

        clientRef.current.on("messagedeleted", (channel, username, deletedMessage, userstate) => {

        });

        clientRef.current.on("timeout", (channel, c_username, reason, duration) => {
            if (auth && auth.authInfo.loginName === c_username) {
                const message = t('tmi_system.temporary_timeout', { duration });
                addMessage({ type: 'system', message });
            }
            setChatList(chatList => {
                return chatList.map(c => {
                    if (c.type !== 'system' && c.userstate) {
                        c.removed = (auth && auth.authInfo ? auth.authInfo.loginName : '') === c_username;
                    }
                    return c;
                });
            });
        });

        clientRef.current.on('ban', (channel, c_username, reason) => {
            setChatList(chatList => {
                return chatList.map(c => {
                    if (c.type !== 'system' && c.userstate) {
                        c.removed = (auth && auth.authInfo ? auth.authInfo.loginName : '') === c_username;
                    }
                    return c;
                });
            });
        });

        clientRef.current.on("raided", (channel, username, viewers) => {
            const message = t('tmi_system.raid', { username, channel, viewers });
            addMessage({
                type: 'userNotice', channel, userstate: undefined, message
            });
        });

        clientRef.current.on("subscribers", (channel, enabled) => {
            const message = enabled ? t('tmi_system.subscriber_only_enabled') : t('tmi_system.subscriber_only_disabled');
            addMessage({ type: 'system', message });
        });

        clientRef.current.on('hosting', (channel, target, viewers) => {
            const message = t('tmi_system.host', { target });
            addMessage({ type: 'system', message });
        });

        clientRef.current.on('notice', (channel, msgid, message) => {
            addMessage({ type: 'system', message });
        });

    }, [options.auth, EmoteSets]);

    return {
        clientRef,
        userColorMapRef,
        addMessage,
        clearChat,
        deleteOldMessage,
        chatList,
        setChatList,
        cloneChatList,
        setCloneChatList,
        connStatus,
    };
}