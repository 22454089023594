import React, { Dispatch, SetStateAction } from "react";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import ChannelList from "./ChannelList";
import { useRecentChannelContext } from '../../context/RecentChannelContext';
import {
    Context as TBCContext,
    ChannelInterface,
} from 'twitch-badge-collector-cc';

function useFollowedStreamsWithUserInfo(userId: string) {
    const [cursor, setCursor] = React.useState<string | null>(null);
    const [channelUserList, setChannelUserList] = React.useState<string[]>([]);
    const [followedChannelList, setFollowedChannelList] = React.useState<ChannelInterface.ChannelInfoInterface[]>([]);
    const nextCursor = React.useRef('');
    const channelListRefetchTime = React.useRef(1000 * 60 * 10);
    const queryClient = useQueryClient();
    const twitchAPI = TBCContext.useTwitchAPIContext();

    const { data: followedChannel, error: fcError } = useQuery(
        ["followedStreams", cursor],
        () => twitchAPI.fetchFollowedStreams(userId, cursor),
        {
            enabled: userId !== '' && typeof cursor !== 'undefined',
        }
    );

    const { data: followedChannelWithUser, fetchStatus, error: userError } = useQuery(
        ['User', channelUserList],
        () => twitchAPI.fetchUser('login', ...channelUserList),
        {
            enabled: channelUserList.length > 0,
            select: React.useCallback((data: any) => {
                const userMap = new Map();

                for (let u of data.data) {
                    userMap.set(u.login, u.profile_image_url);
                }

                if (!followedChannel) return;

                return followedChannel.data.map((f: any) => {
                    return {
                        profileImgUrl: userMap.get(f.user_login),
                        displayName: f.user_name,
                        loginName: f.user_login,
                        category: f.game_name,
                    } as ChannelInterface.ChannelInfoInterface;
                });
            }, [followedChannel])
        }
    );

    const getMoreFollowedStreams = () => {
        setCursor(nextCursor.current)
    }

    React.useEffect(() => {
        if (cursor !== null) return;
        if (!followedChannelWithUser) return;

        setFollowedChannelList(list => {
            if (cursor === null) {
                return followedChannelWithUser;
            } else {
                return list;
            }
        });
    }, [followedChannelWithUser, cursor]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            queryClient.removeQueries(['followedStreams']);
            setCursor(null);
        }, channelListRefetchTime.current);

        return () => clearInterval(timer);
    }, [cursor]);

    React.useEffect(() => {
        if (!followedChannel) return;

        const userList = followedChannel.data.map((e: any) => {
            return e.user_login;
        });
        setChannelUserList(userList);

        nextCursor.current = followedChannel.pagination.cursor;
    }, [followedChannel]);

    React.useEffect(() => {
        if (!followedChannelWithUser) return;

        setFollowedChannelList(list => {
            if (list.length === 0) return followedChannelWithUser;

            const newList = followedChannelWithUser.filter((fl: any) => {
                return fl.profileImgUrl && !list.some(old => old.loginName === fl.loginName);
            });
            return [...list, ...newList];
        });
    }, [followedChannelWithUser]);

    const error = fcError || userError;

    return {
        data: followedChannelList,
        cursor,
        fetchStatus,
        error,
        getMoreFollowedStreams
    }
}

export default function ChannelDialog(props: {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
}) {
    const handleDialogClose = () => props.setOpen(false);
    const AuthCtx = TBCContext.useAuthContext();
    const recentChannel = useRecentChannelContext().recentChannel;
    const { channel, setChannel } = TBCContext.useChannelInfoContext();
    const [channelText, setChannelText] = React.useState('');
    const [isFsFetching, setIsFsFetching] = React.useState(false);
    const [userId, setUserId] = React.useState('');
    const { data: fsData, cursor, fetchStatus: FsFetchStatus, error, getMoreFollowedStreams } = useFollowedStreamsWithUserInfo(userId);
    const { t } = useTranslation();

    React.useEffect(() => {
        if (!AuthCtx) return;
        if (!AuthCtx.authInfo.id) return;

        setUserId(AuthCtx.authInfo.id);
    }, [AuthCtx]);

    React.useEffect(() => {
        handleDialogClose();
    }, [channel]);

    React.useEffect(() => {
        if(FsFetchStatus === 'fetching') {
            setIsFsFetching(true);
        }else if(FsFetchStatus === 'idle') {
            setIsFsFetching(false);
        }
    }, [FsFetchStatus]);

    const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChannelText(e.target.value)
    }

    const onButtonClick = React.useCallback(() => {
        setChannel({ type: 'login', value: channelText });
    }, [channelText])

    return (
        <Dialog
            open={props.open}
            onClose={handleDialogClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                {t('setting.channel_setting')}
                <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Stack direction="row" spacing={1} sx={{ 'margin': '8px' }}>
                <TextField placeholder={t('common.channel_name')} size='small' value={channelText} onChange={onTextFieldChange}></TextField>
                <Button variant='contained' onClick={onButtonClick}>{t('common.join')}</Button>
            </Stack>

            <List
                sx={{ width: '100%', maxWidth: 340 }}
                dense={true}
                subheader={<ListSubheader disableSticky={true}>{t('common.recent_channel')}</ListSubheader>}
            >
                {
                    recentChannel.length > 0 ? (
                        <ChannelList channelList={recentChannel || []} />
                    ) : (
                        <Stack
                            spacing={2}
                            direction='row'
                            justifyContent='center'
                        >
                            <Typography variant="subtitle2">
                                {t('common.no_list')}
                            </Typography>
                        </Stack>
                    )
                }

            </List>

            <List
                sx={{ width: '100%', maxWidth: 340 }}
                dense={true}
                subheader={<ListSubheader disableSticky={true}>{t('common.online')}</ListSubheader>}
            >
                {error ? (
                    <span>{t('common.error')}</span>
                ) : (
                    fsData && fsData.length > 0 ? (
                        <>
                            <ChannelList channelList={fsData} />

                            {
                                typeof cursor !== 'undefined' ? (

                                    <LoadingButton 
                                        onClick={getMoreFollowedStreams}
                                        loading={isFsFetching} 
                                        sx={{'margin-left': '8px'}}
                                    >
                                        {t('common.show_more')}
                                    </LoadingButton>
                                ) : null
                            }
                        </>
                    ) : (
                        <Stack
                            spacing={2}
                            direction='row'
                            justifyContent='center'
                        >
                            <Typography variant="subtitle2">
                                {t('common.no_list')}
                            </Typography>
                        </Stack>
                    )
                )}
            </List>
        </Dialog>
    )
}