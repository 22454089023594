import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { styled } from '@mui/material/styles';
import ChatRoomContainer from './chat-room-container';
import ChatRoomFooter from './chat-room-footer';
import ChatRoomHeader from './chat-room-header';
import { useRecentChannelContext } from '../../context/RecentChannelContext';
import useClient from '../../hooks/useClient';
import { 
    Context as TBCContext,
    ChannelInterface,
    useChatSaverBroadcaster,
    TwitchAPIInterface
} from 'twitch-badge-collector-cc';

const ChatRoomStyle = styled('div')(({theme}) => ({
    width: '28rem',
    borderLeft: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,

    '#chat-room-container': {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 8.4rem)',
    },
    '.chat-list.origin': {
        flex: '0.75 1 0%',
    },
    '.chat-list.clone': {
        flex: '0.25 1 0%',
    }
}));

function ChatRoom() {
    const { recentChannel, setRecentChannel } = useRecentChannelContext();
    const authCtx = TBCContext.useAuthContext();
    const client = useClient({auth: authCtx});
    const { channel, setChannel, channelInfo, User } = TBCContext.useChannelInfoContext();
    const twitchAPI = TBCContext.useTwitchAPIContext();

    useChatSaverBroadcaster(client.cloneChatList, channel, channelInfo, 'live', 'Web');

    const updateRecentChannel = (data: TwitchAPIInterface.User) => {
        const newRecentChannel = {
            profileImgUrl: data.profile_image_url,
            displayName: data.display_name,
            loginName: data.login
        } as ChannelInterface.ChannelInfoInterface;

        const channelAvail = recentChannel.some((e: any) => e.loginName === data.login ? true : false);

        if(!channelAvail){
            if(recentChannel.length >= 4){
                recentChannel.shift();
            }
            setRecentChannel([...recentChannel, newRecentChannel]);
        }
    }

    React.useEffect(() => {
        if(typeof User === 'undefined') return;

        const userData = User.data[0];

        if(typeof userData === 'undefined') return;

        document.title = `${userData.display_name} - TBC`;
        updateRecentChannel(userData);
    }, [channel, User]);
    
    return (
        <ChatRoomStyle id="chat-room-body">
            <ChatRoomHeader></ChatRoomHeader>
            <ChatRoomContainer
                chatList={client.chatList}
                cloneChatList={client.cloneChatList}
                userColorMapRef={client.userColorMapRef} />
            <ChatRoomFooter
                clientRef={client.clientRef}
                setChatList={client.setChatList} />
        </ChatRoomStyle>
    )
}

export default ChatRoom;