import React, { createContext, useContext } from "react";
import {
  ChannelInterface
} from 'twitch-badge-collector-cc';

export const RecentChannelContext = createContext<{recentChannel: ChannelInterface.ChannelInfoInterface[], setRecentChannel: React.Dispatch<React.SetStateAction<ChannelInterface.ChannelInfoInterface[]>>} | undefined>(undefined);

export function useRecentChannelContext() {
  const context = useContext(RecentChannelContext);
  if (typeof context === 'undefined') {
    throw new Error("RecentChannelContext must be within RecentChannelProvider");
  }

  return context;
}