import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { BroadcastChannel } from 'broadcast-channel';
import { useTranslation } from "react-i18next";
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import useVH from 'react-viewport-height';
import { ThemeProvider } from '@mui/material/styles';
import Main from './Main';
import Remote from './Remote';
import globalStyles from './style/global';
import { inIframe } from '../utils/utils';
import { 
  Context as TBCContext,
  useGlobalSetting,
  useTwitchAPI,
  useAlert,
  useArrayFilter,
  useCustomTheme,
  CustomTheme,
  Filter, 
  ChatSaverWeb,
  AlertContainer,
  SettingPageDrawer,
  DrawerTemplate,
} from 'twitch-badge-collector-cc';

function App() {
  const { globalSetting, dispatchGlobalSetting } = useGlobalSetting('Web');
  const twitchAPI = useTwitchAPI(import.meta.env.DEV);
  const { alerts, setAlerts, addAlert } = useAlert();
  const { t, i18n } = useTranslation();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false
      },
    },
    queryCache: new QueryCache({
      onError: (error) =>
        addAlert({ serverity: 'error', message: t('alert.network_error') })
    }),
  });

  React.useEffect(() => {
    if (inIframe()) return;

    window.localStorage.setItem('globalSetting', JSON.stringify(globalSetting));
    i18n.changeLanguage(globalSetting.language);
    document.documentElement.lang = globalSetting.language!;
  }, [globalSetting]);

  useVH();

  const customTheme = useCustomTheme(globalSetting.darkTheme);

  return (
    <ThemeProvider<CustomTheme> theme={customTheme}>
        <TBCContext.GlobalSettingContext.Provider value={{ globalSetting, dispatchGlobalSetting }}>
          <TBCContext.AlertContext.Provider value={{ alerts, setAlerts, addAlert }}>
            <QueryClientProvider client={queryClient}>
              <TBCContext.TwitchAPIContext.Provider value={twitchAPI}>
                <Router />
              </TBCContext.TwitchAPIContext.Provider>
            </QueryClientProvider>
          </TBCContext.AlertContext.Provider>
        </TBCContext.GlobalSettingContext.Provider>
    </ThemeProvider>
  );
}

function Router() {
  const { t } = useTranslation();
  const { arrayFilter, setArrayFilter, addArrayFilter, checkFilter } = useArrayFilter();

  return (
    <TBCContext.ArrayFilterContext.Provider value={{ arrayFilter, setArrayFilter, addArrayFilter, checkFilter }}>
      <ReactQueryDevtools initialIsOpen={false} />
      {globalStyles}
      <AlertContainer />
      <Routes>
        <Route path='/' element={<Main />} />
        <Route 
          path='/setting/filter' 
          element={
            <DrawerTemplate title={t('setting.filter_setting')} name='filter' drawer={<SettingPageDrawer env='Web' />}>
              <Filter />
            </DrawerTemplate>
          } 
        />
        <Route 
          path='/setting/chatsaver' 
          element={
            <DrawerTemplate title={t('setting.save_chat')} name='chatsaver' drawer={<SettingPageDrawer env='Web' />} >
              <ChatSaverWeb />
            </DrawerTemplate>
          } 
        />
        <Route path='/mini' element={<Remote type='live' />} />
        <Route path='/replay' element={<Remote type='replay' />} />
      </Routes>
    </TBCContext.ArrayFilterContext.Provider>
  )
}

export default App;