import React, { useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { CommonUserstate } from "tmi.js";
import { useTranslation } from 'react-i18next';
import { useQuery } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import GlobalStyles from '@mui/material/GlobalStyles';
import useClient from "../hooks/useClient";
import {
    Context as TBCContext, 
    SettingInterface,
    BroadcastChannelInterface,
    useChatSaverBroadcaster,
    useChannelInfo,
    ChatInterface,
    ChatContainer,
    Common,
    Utils,
    FilterInterface
} from 'twitch-badge-collector-cc';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { Snackbar, Typography } from "@mui/material";

const RemoteGlobalStyle = (
    <GlobalStyles styles={(theme) => ({
        body: {
            backgroundColor: theme.colors.remoteBgColor
        }
    })} />
)

export default function Remote(props: { type: BroadcastChannelInterface.chatListType }) {
    const params = new URLSearchParams(window.location.search);
    const twitchAPI = TBCContext.useTwitchAPIContext();
    const { 
        channelInfoObject, 
        dispatchChannelInfo, 
        channelInfo, 
        isChannelInfoObjSuccess, 
        channel, 
        setChannel,
        User
    } = useChannelInfo();
    const [clipOffset, setClipOffset] = React.useState(0);
    const replayId = React.useRef(params.get('replay') || '');
    const clipId = React.useRef(params.get('clip') || '');
    const isReplay = replayId.current !== '';
    const isClip = clipId.current !== '';

    const {data: video} = useQuery(
        ['Videos', replayId.current],
        () => twitchAPI.fetchVideos(replayId.current),
        {
            enabled: isReplay
        }
    );

    const {data: clip} = useQuery(
        ['Clip', clipId.current],
        () => twitchAPI.fetchClips(clipId.current),
        {
            enabled: isClip
        }
    );

    React.useEffect(() => {
        if(props.type === 'replay'){
            let value = '';

            if(isReplay && video && video.data.length > 0){
                value = video.data[0].user_login;
                setChannel({
                    type: 'login', value
                });
            }else if(isClip && clip && clip.data.length > 0){
                value = clip.data[0].broadcaster_id;
                setClipOffset(clip.data[0].vod_offset);
                setChannel({
                    type: 'id', value
                });
            }
        }
    }, [video, clip]);

    return (
        <TBCContext.ChannelInfoContext.Provider value={{ channelInfoObject, dispatchChannelInfo, channelInfo, isChannelInfoObjSuccess, channel, setChannel, User }}>
            {RemoteGlobalStyle}
            <RemoteContainer type={props.type} clipOffset={props.type === 'replay' ? clipOffset : 0} />
        </TBCContext.ChannelInfoContext.Provider>
    )
}

function RemoteContainer(props: { type: BroadcastChannelInterface.chatListType, clipOffset: number }) {
    const { dispatchGlobalSetting } = TBCContext.useGlobalSettingContext();
    const { channel, setChannel, channelInfo, isChannelInfoObjSuccess } = TBCContext.useChannelInfoContext();
    const client = useClient({remoteType: props.type});
    const { arrayFilter, setArrayFilter } = TBCContext.useArrayFilterContext();
    const [extChannel, setExtChannel] = useState('');
    const [playerTime, setPlayerTime] = React.useState(0);
    const { t, i18n } = useTranslation();
    const { addAlert } = TBCContext.useAlertContext();
    const [userChannel, setUserChannel] = useState('');
    const [openModal, setOpenModal] = React.useState(false);
    const [userChannelError, setUserChannelError] = useState(false);
    const handleModalOpen = () => setOpenModal(true);
    const handleModalClose = (e: object, reason: string) => {
        if(reason === 'backdropClick') return;
        setOpenModal(false);
    }
    
    useChatSaverBroadcaster(client.cloneChatList, channel, channelInfo, props.type, 'Extension');

    const onMessage = useCallback((e: MessageEvent<any>) => {
        if (e.data.sender !== 'extension') return;

        const type = e.data.type;
        const value = e.data.value;

        /**
         * PLAYER_TIME: VOD player time.
         * CHAT_LIST: VOD chat lists.
         */

        if (type === 'PLAYER_TIME') {
            setPlayerTime(Math.ceil(value) + props.clipOffset);
        } else if (type === 'ARRAY_FILTER') {
            const platformFilter = Utils.getFilterByPlatform('twitch', value as FilterInterface.ArrayFilterListInterface[]);
            setArrayFilter(platformFilter);

            addAlert({
                message: t('alert.filter_updated'),
                serverity: 'info'
            });
        } else if (type === 'CHAT_LIST') {
            const data = value.body.data;

            console.log('[web] Remote CHAT_LIST: ', data);
            
            parseReplayChats(data).forEach(msg => {
                client.addMessage(msg); 
            });

            client.deleteOldMessage('clone');

        } else if (type === 'CHANNEL_DATA') {
            if (props.type === 'live') {
                if (extChannel === '') {
                    setExtChannel(value.channel);

                    window.parent.postMessage({
                        sender: 'wtbc',
                        type: 'CHANNEL_DATA_RECEIVED',
                        value: null
                    }, '*');
                }
            }
        } else if (type === 'CHANNEL_NOT_RESOLVED') {
            handleModalOpen();
        } else if (type === 'EXTENSION_SETTING') {
            const setting = value as SettingInterface.Setting;
            
            if (typeof setting.miniChatTime !== 'undefined') { 
                dispatchGlobalSetting({ type: "chatTime", value: setting.miniChatTime });
            }
            if (typeof setting.darkTheme !== 'undefined') {
                dispatchGlobalSetting({ type: "darkTheme", value: setting.darkTheme });
            }
            if (typeof setting.miniLanguage !== 'undefined') {
                dispatchGlobalSetting({ type: "language", value: setting.miniLanguage });
                i18n.changeLanguage(value.miniLanguage);
            }
            if (typeof setting.miniFontSize !== 'undefined') {
                dispatchGlobalSetting({ type: "chatFontSize", value: setting.miniFontSize });
            }
            if (typeof setting.maximumNumberChats !== 'undefined') {
                dispatchGlobalSetting({ type: "maximumNumberChats", value: setting.maximumNumberChats });
            }
        } else if (type === 'TWITCH_DARKMODE') {
            dispatchGlobalSetting({ type: "darkTheme", value });
        }
    }, [extChannel]);

    const handleChannelFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserChannel(event.target.value);
    };

    const handleUserChannelInput = () => {
        confirmUserChannel();
    }

    const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if(event.key !== 'Enter') return;
        confirmUserChannel();
    }

    const confirmUserChannel = useCallback(() => {
        if(!Utils.validateTwitchUsername(userChannel)) {
            setUserChannelError(true)
            return;
        }
        setChannel({type: 'login', value: userChannel});
        setOpenModal(false);
    }, [userChannel])

    useEffect(() => {
        setChannel({type: 'login', value: extChannel})

        ReactGA.event({
            category: "Remote",
            action: props.type === 'live' ? 'live' : 'others',
            label: extChannel,
        });
    }, [extChannel])

    React.useEffect(() => {
        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        }
    }, []);

    React.useEffect(() => {
        if(!isChannelInfoObjSuccess) return;

        if (props.type === 'replay') {
            window.parent.postMessage({
                sender: 'wtbc',
                type: 'REQUEST_CHAT_LIST',
                value: null
            }, '*');
        }
    }, [isChannelInfoObjSuccess]);

    React.useEffect(() => {
        window.parent.postMessage({
            sender: 'wtbc',
            type: 'REQUEST_CHANNEL_ID',
            value: null
        }, '*');
    }, []);

    React.useEffect(() => {
        window.parent.postMessage({
            sender: 'wtbc',
            type: 'REQUEST_EXTENSION_SETTING',
            value: null
        }, '*');
    }, []);

    return (
        <TBCContext.UserColorContext.Provider value={client.userColorMapRef}>
            <ChatContainer
                messageList={client.cloneChatList}
                type={'clone'}
                playerTime={playerTime}
            />
            <Snackbar
                open={client.connStatus === 'disconnected'}
                message="Disconnected from chat server."
            />

            <Modal
                open={openModal}
                onClose={handleModalClose}
            >
                <Common.ModalBox>
                    <Stack spacing={2.4} sx={{ margin: '8px' }}>
                        <Stack spacing={1}>
                            <Typography variant='h6'>
                                {t('common.channelNotFound')}
                            </Typography>
                            <Typography variant='subtitle2'>
                                {t('common.requestChannelName')}
                            </Typography>
                        </Stack>
                        
                        <Stack direction='row' spacing={1} sx={{ width: '100%' }}>
                            <TextField 
                                label={t('common.channel_name')}
                                value={userChannel}
                                error={userChannelError}
                                onChange={handleChannelFieldChange}
                                inputProps={{onKeyUp: handleKeyUp}}
                                variant="outlined" 
                                size="small" 
                            />
                            <Button onClick={handleUserChannelInput}>{t('common.confirm')}</Button>
                        </Stack>
                    </Stack>

                </Common.ModalBox>
            </Modal>
        </TBCContext.UserColorContext.Provider>
    )
}

function parseReplayChats(data: any) {
    const msgs: ChatInterface.MessageInterface[] = [];

    for (let edge of data.video.comments.edges) {
        const node = edge.node;
        const commenter = node.commenter;

        if (commenter === null) {
            continue;
        }
        const commenterId = node.commenter.id;
        const message = node.message;
        const userBadges = message.userBadges;
        const contentOffsetSeconds = node.contentOffsetSeconds;
        const messageFragments = message.fragments;
        const userColor = message.userColor;

        const messageId = node.id;
        const displayName = commenter.displayName;
        const login = commenter.login;

        let badges = [];
        let badgesRaw;

        for (let badge of userBadges) {
            badges.push(`${badge.setID}/${badge.version}`)
        }

        badgesRaw = badges.join(',');

        let messages = [];
        let messageText: string;
        const emotes = {} as CommonUserstate['emotes'];

        let idx = 0;

        for (let msgfrag of messageFragments) {
            const emote = msgfrag.emote;
            const text = msgfrag.text;

            idx = idx + text.length;

            if (emote) {
                if (emotes![emote.emoteID]) {
                    emotes![emote.emoteID].push(`${emote.from}-${idx - 1}`);
                } else {
                    emotes![emote.emoteID] = [`${emote.from}-${idx - 1}`];
                }
            }

            messages.push(text);
        }

        messageText = messages.join('');

        msgs.push({
            type: 'message',
            message: messageText,
            userstate: {
                'display-name': displayName,
                username: login,
                id: `${messageId}-${contentOffsetSeconds}`,
                'user-id': commenterId,
                emotes: emotes,
                color: userColor,
                'badges-raw': badgesRaw,
                'tmi-sent-ts': contentOffsetSeconds
            },
            id: nanoid(),
            replay: true
        });
    }

    return msgs;
}