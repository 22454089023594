import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import React from 'react';
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import i18n from 'twitch-badge-collector-cc';

ReactGA.initialize('G-QGW6F88RBK', {
  gtagOptions: {
    cookie_flags: 'secure;samesite=none'
  }
});

Sentry.init({
  dsn: "https://6215aa8bc4644552bbc4c261ab217429@o1197585.ingest.sentry.io/4504352160088064",
  integrations: [new BrowserTracing()],
  release: import.meta.env.TBC_RELEASE,
  tracesSampleRate: 0.44,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
