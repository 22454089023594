import { ChannelInterface } from 'twitch-badge-collector-cc'
import Channel from "./Channel";

export default function ChannelList(props: { channelList: ChannelInterface.ChannelInfoInterface[] }) {
    return (
        <>
            {
                props.channelList.map((e: ChannelInterface.ChannelInfoInterface)=> {
                    return (
                        <Channel key={e.loginName} channel={e}></Channel>
                    );
                })
            }
        </>
    )
}