import React from 'react';
import HomeScreen from './Home';
import { styled } from '@mui/material/styles';
import { Context as TBCContext } from 'twitch-badge-collector-cc';

const EMBED_URL = 'https://embed.twitch.tv/embed/v1.js';

const PlayerStyle = styled('div')({
    width: '100%',
    height: 'inherit',
    
    iframe: {
        border: 'none',
        display: 'block',
    }
})

function Player() {
    const [ player, setPlayer ] = React.useState(null);
    const { channel } = TBCContext.useChannelInfoContext();
    const { globalSetting } = TBCContext.useGlobalSettingContext();

    React.useEffect(() => {
        if(globalSetting.player === 'off'){
            setPlayer(null);
            return;
        }

        if(player) return;
        if(!channel) return;
        if(channel.value === '') return;

        const script = document.createElement('script');
        script.setAttribute('src', EMBED_URL);

        script.addEventListener('load', () => {
            let player = new (window as any).Twitch.Player('twitch-player__container', {
                width: '100%',
                height: '100%',
                channel: channel.value,
            });
            setPlayer(player);
        });
        document.body.appendChild(script);

    }, [channel, globalSetting.player]);


    React.useEffect(() => {
        if(player && channel && channel.value !== ''){
            (player as any).setChannel(channel.value);
        }
    }, [channel]);

    React.useEffect(() => {
        if(globalSetting.player === 'on'){
            document.getElementById('chat-room-body')?.classList.remove('chat-with-player');
        }else{
            document.getElementById('chat-room-body')?.classList.add('chat-with-player');
        }
    }, [globalSetting.player]);

    if(globalSetting.player === 'off'){
        return null;
    }

    if (channel && channel.value !== '') {
        return (
            <PlayerStyle id="twitch-player__container" />
        )
    } else {
        return <HomeScreen id="twitch-player__container" />;
    }
}

export default Player;
