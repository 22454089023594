import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Setting } from 'twitch-badge-collector-cc';

export function SettingDialog(props: {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    expand?: boolean
}) {
    const handleDialogClose = () => props.setOpen(false);
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.open}
            onClose={handleDialogClose}
            fullWidth
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">
                {t('common.setting')}
                <IconButton
                    aria-label="close"
                    onClick={handleDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

            </DialogTitle>

            <List dense={false}>
                <ListItemButton href='/setting/filter' target='_blank'>
                    <ListItemIcon>
                        <FilterAltIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('setting.filter_setting')}
                    />
                </ListItemButton>

                <ListItemButton href='/setting/chatsaver' target='_blank'>
                    <ListItemIcon>
                        <ImageIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={t('setting.save_chat')}
                    />
                </ListItemButton>
            </List>

            <Setting option='ALL'/>
        </Dialog>
    )
}