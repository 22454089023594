import { useState } from "react";
import { getLocalStorageObject } from "../utils/utils";
import { 
    ChannelInterface,
} from 'twitch-badge-collector-cc';

export default function useRecentChannel() {
    const localRecentChannel: ChannelInterface.ChannelInfoInterface[] = getLocalStorageObject('recently_connected_channel') || [];
    const [recentChannel, setRecentChannel] = useState(localRecentChannel);
    
    return { recentChannel, setRecentChannel };
}


