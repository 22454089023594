import React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { 
    Context as TBCContext,
    ChatContainer,
    ChatInterface,
    SettingInterface
} from 'twitch-badge-collector-cc';

type HandlerProps = {
    setDrag: (drag: boolean) => void;
}

const ChatRoomHandler = styled('div')(({ theme }) => ({
    cursor: 'row-resize',
    margin: '4px',
    hr: {
        border: `1px solid ${theme.palette.divider}`
    },
}));

function Handler(props: HandlerProps) {

    const dragStart = React.useCallback((e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        e.stopPropagation();
        props.setDrag(true);
    }, []);

    return (
        <ChatRoomHandler id='handler'
            onMouseDown={dragStart}
            onTouchStart={dragStart}
        >
            <hr />
        </ChatRoomHandler>
    )
}

const ChatRoomContainerStyle = styled(Stack)({
    flex: '1',
    overflow: 'hidden',
});

interface ChatRoomContainerProps {
    chatList: ChatInterface.MessageInterface[];
    cloneChatList: ChatInterface.MessageInterface[];
    userColorMapRef: React.MutableRefObject<Map<string, string>>;
}

export default function ChatRoomContainer(props: ChatRoomContainerProps) {
    const chatList = props.chatList;
    const cloneChatList = props.cloneChatList;
    const ratio = React.useRef<number>(0);
    const isDrag = React.useRef(false);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const { globalSetting, dispatchGlobalSetting } = TBCContext.useGlobalSettingContext();

    const position = globalSetting.position;
    const isPosUp = position === 'up';
    const isPosDown = position === 'down';

    React.useEffect(() => {
        let localRatio = localStorage.getItem('chatRoomRatio');
        let _ratio = parseInt(localRatio ? localRatio : '30');

        ratio.current = _ratio;

        if (localRatio === null) {
            localStorage.setItem('chatRoomRatio', _ratio.toString());
        }

        updateContainerRatio(containerRef, position, _ratio);
    }, []);

    const setDrag = (drag: boolean) => {
        isDrag.current = drag;
    }

    const doDrag = React.useCallback((e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        const pressed = 'buttons' in e ? e.buttons : null;

        if (pressed === 0) {
            isDrag.current = false;
        }

        if (isDrag.current) {
            const clientY = "touches" in e ? e.touches[0].clientY : e.clientY;
            const rect = e.currentTarget.getBoundingClientRect();

            let container_ratio = (1 - (clientY - rect.y) / rect.height) * 100;
            container_ratio = Math.max(0, Math.min(100, Math.round(container_ratio)));

            ratio.current = container_ratio;

            updateContainerRatio(containerRef, position, ratio.current);
        }
    }, [position]);

    const endDrag = React.useCallback((e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        isDrag.current = false;
        localStorage.setItem('chatRoomRatio', ratio.current.toString());
    }, []);

    return (
        <ChatRoomContainerStyle
            direction='column'
            id='chat-room-container'
            onMouseMove={doDrag}
            onMouseUp={endDrag}
            onMouseLeave={endDrag}

            onTouchMove={doDrag}
            onTouchEnd={endDrag}
            ref={containerRef}
        >
            <TBCContext.UserColorContext.Provider value={props.userColorMapRef}>
                <ChatContainer
                    messageList={isPosUp ? cloneChatList : chatList}
                    type={isPosUp ? 'clone' : 'origin'}
                />
                <Handler setDrag={setDrag}></Handler>
                <ChatContainer
                    messageList={isPosDown ? cloneChatList : chatList}
                    type={isPosDown ? 'clone' : 'origin'}
                />
            </TBCContext.UserColorContext.Provider>
        </ChatRoomContainerStyle>
    )
}

function updateContainerRatio(containerRef: React.RefObject<HTMLDivElement>, position: SettingInterface.PositionOptionsType, ratio: number) {
    const container = containerRef.current;

    if (!container) return;

    const isPosUp = position === 'up';
    const isPosDown = position === 'down';

    const first_container = (container.getElementsByClassName(isPosUp ? 'clone' : 'origin')[0] as HTMLDivElement);
    const second_container = (container.getElementsByClassName(isPosDown ? 'clone' : 'origin')[0] as HTMLDivElement);

    let orig_size = ratio === 0 ? 1 : (ratio === 10 ? 0 : 1);
    let clone_size = ratio === 0 ? 0 : (ratio === 10 ? 1 : 0);

    if (1 <= ratio && ratio <= 100) {
        clone_size = parseFloat((ratio * 0.01).toFixed(2));
        orig_size = parseFloat((1 - clone_size).toFixed(2));
    }

    first_container.style.flex = String(orig_size);
    second_container.style.flex = String(clone_size);
}