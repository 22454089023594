import React from 'react';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import Player from './Player';
import ChatRoom from './chatroom/chat-room';
import useRecentChannel from '../hooks/useRecentChannel';
import { AuthContext } from '../context/Auth';
import { RecentChannelContext } from '../context/RecentChannelContext';
import {
  useAuth,
  useChannelInfo,
  Context as TBCContext
} from 'twitch-badge-collector-cc'

const MainContainerStyle = styled(Stack)(({theme}) => ({
  '@media screen and (max-width: 700px)': {
    flexDirection: 'column',

    '#twitch-player__container': {
      height: '56.25vw',
    },

    '#chat-room-body': {
      flex: '1',
      width: '100%',
      overflow: 'hidden',
      borderLeft: 'none',
      borderTop: `1px solid ${theme.colors.bgColor_3}`,
    }
  }
}))

export default function Main() {
  const Auth = useAuth();
  const params = new URLSearchParams(window.location.search);
  const { recentChannel, setRecentChannel } = useRecentChannel();
  const { channelInfoObject, dispatchChannelInfo, channel, setChannel, channelInfo, User } = useChannelInfo();

  const matches = useMediaQuery('(max-width: 700px)');

  React.useEffect(() => {
    window.localStorage.setItem('recently_connected_channel', JSON.stringify(recentChannel));
  }, [recentChannel]);

  React.useEffect(() => {
    setChannel({ type: 'login', value: params.get('channel') || '' });
  }, []);

  return (
    <TBCContext.ChannelInfoContext.Provider value={{ channelInfoObject, dispatchChannelInfo, channel, setChannel, channelInfo, User }}>
      <TBCContext.AuthContext.Provider value={Auth}>
        <RecentChannelContext.Provider value={{ recentChannel, setRecentChannel }}>
          <MainContainerStyle direction={matches ? 'column' : 'row'} id='main'>
            <Player />
            <ChatRoom />
          </MainContainerStyle>
        </RecentChannelContext.Provider>
      </TBCContext.AuthContext.Provider>
    </TBCContext.ChannelInfoContext.Provider>
  )
}
